import React from "react"
import Layout from "../../components/layout"
import monitoring from "../../assets/casestudies/unity-monitoring.png"
import checkout from "../../assets/casestudies/unity-checkout.png"
import admin from "../../assets/casestudies/unity-admin.png"
import data from "../../assets/casestudies/unity-data.png"
import unity from "../../assets/unity.png"
import CenteredContent from "../../components/centeredContent"
import FullWidthContent from "../../components/fullWidthContent"

const Unity = () => (
  <Layout headerType="unity" headerText="Unity">
    <CenteredContent
      image1={unity}
      header="Unity"
      body="Currently, at Unity, I lead the Subscription and Metered Billing teams on the Commerce Platform, overseeing millions of transactions that generate substantial revenue for the company. My role focuses on ensuring seamless, efficient, and scalable operations while aligning with customer expectations and business growth. By bridging the gap between product development, finance, and other teams, I work collaboratively to enhance billing processes and deliver a service that truly resonates with our users."
      rotation="3deg"
    />
    <FullWidthContent
      bgcolor1={"#f1f1f1"}
      image={data}
      header="Metered Billing Data Flows"
      body="As part of Unity's Commerce Platform team, my team oversees the data pipeline powering the pay-as-you-go billing system for Unity Cloud gaming services. We handle high-volume data processing, ensuring the pipelines are reliable, secure, and scalable to support the company's financial operations and growth."
      rotation="3deg"
      bottomAngleMargin="-60px"
      bgPosition="top center"
    />
    <CenteredContent
      image1={checkout}
      header="Back End Billing Systems"
      body="My team handles the backend systems that support Unity's checkout and subscription management flows. We focus on making these systems reliable, secure, and high-performing to ensure smooth transactions and great user experiences. By consistently enhancing these critical elements, we ensure our services operate seamlessly, contributing to elevated customer satisfaction."
      rotation="3deg"
    />
    <FullWidthContent
      bgcolor1={"#f1f1f1"}
      image={monitoring}
      header="Monitoring & Alerting"
      body="Our team at Unity designed and implemented a robust monitoring and alerting system for our billing pipeline. This system ensures real-time oversight of transactional processes, enabling proactive identification and resolution of issues to maintain service reliability and financial accuracy. "
      rotation="-3deg"
      bottomAngleMargin="-60px"
      bgPosition="top center"
    />
    <CenteredContent
      image1={admin}
      header="Customer Support Admin Portal
      "
      body="My teams play a crucial role in developing and refining both the front-end and back-end systems of the customer support admin portal. Our work ensures that support teams can swiftly resolve billing inquiries and deliver exceptional service to our users, ultimately reinforcing customer satisfaction and trust."
      rotation="3deg"
    />
  </Layout>
)

export default Unity
